.header-section {
    background-color: var(--headerSectionBackgroundColor);
    color: var(--headerSectionTextColor);
    line-height: 1.5;
    text-align: center;
    padding: 30px 0px;
}

.header-section h2 {
    margin-block: 0px;
    /* Center whole div on background */
    font-family: var(--decorFont);
    text-transform: uppercase;
    font-size: 36px;
}

.header-section .subheading {
    font-size: 1.1rem;
    font-weight: 300;
    width: 75%;
    margin: 15px auto 0px;
}

.header-section h2::after {
    background-color: gray;
    content: "";
    display: block;
    height: 1px;
    width: 120px;
    margin: 15px auto 0px;
}

.container {
    margin-left: auto;
    margin-right: auto;
    /*max-width: 1170px;*/
    max-width: var(--wholePageMaxWidth);
    /*width: 100%;*/
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0%;
    padding-bottom: 0%;
}

.section.locations .location-list {
    list-style-type: none;
    padding: 0px;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.section.locations .location {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 900px;
    text-align: center;
    gap: 1rem 2rem;
    margin: 0px auto 2rem;
}

.section.locations .location-map {
    flex-shrink: 0;
    position: relative;
    text-align: center;
    width: 200px;
}

.section.locations .location-map .location-map-image.hexagon img {
    /*clip-path: polygon(50% 1%, 95% 25%, 95% 75%, 50% 99%, 5% 75%, 5% 25%); */
}

.section.locations .location-map .location-map-image {
    height: 200px;
    position: relative;
    width: 200px;
    margin: 0px auto;
    overflow: hidden;
    border-radius: 50%;
}

.schedule-section .activities {
    list-style-type: none;
    max-width: 900px;
    padding: 0px;
    margin: 0px auto;
}

.schedule-section .activity {
    margin-bottom: 2rem;
    text-align: center;
}

.schedule-section .activity-headline {
    font-size: 1.4rem;
    text-transform: var(--headline-text-transform);
    margin: 0px;
}

.schedule-section .activity-time {
    font-size: 1.1rem;
    text-transform: var(--headline-text-transform);
    margin: 0px;
}
.schedule-section .activity-subtitle {
    font-size: 1.1rem;
    text-transform: var(--headline-text-transform);
    margin: 0px;
}

.schedule-section .activity-date {
    font-size: 1.8rem;
    text-transform: var(--headline-text-transform);
}

.schedule-section .activity-description {
    font-weight: 300;
    margin: 3px 0px 0px;
}

.schedule-section .icon-wrapper .icon {
    margin-top: 15px;
    -webkit-box-align: center;
    align-items: center;
    color: var(--textOnPrimaryColor);
    display: inline-flex;
    font-size: 2.5rem;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    text-align: center;
    z-index: 2;
    background: var(--primaryColor);
    border: 5px var(--primaryColor);
    border-radius: 50%;
    padding: 0.5rem;
}

.schedule-section .icon-wrapper .icon img {
    height: 40px;
}

.schedule-section .activity::after {
    content: "";
    display: block;
    height: 1.5rem;
    width: 1px;
    background: var(--primaryColor);
    margin: 1rem auto -1rem;
}

.loading-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.loading-box.insite {
    height: auto;
}

.location-details h3 {
    margin-top: 0px;
    margin-bottom: 0.5rem;
}

.location-details h2 {
    margin-bottom: 0.5rem;
}

.location-details p {
    margin-top: 0px;
}