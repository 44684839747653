.image-container {
  position: relative;
  display: inline-block; /* Ensures the container fits around the image */
  max-width: var(--wholePageMaxWidth);
}

.image {
  display: block;
  width: 100%; /* Adjust as needed */
  height: auto; /* Adjust as needed */
}

.text-overlay {
  position: absolute;
  top: 0; /* Positions the text at the top */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.7); /*Adjust background color and opacity */
  padding: 10px;
  text-align: center;
  width: 100%; /* Spans the entire width of the image */
  box-sizing: border-box; /* Includes padding in width calculations */
}

.text-overlay p {
  font-family: var(--decorFont);
  text-transform: uppercase;
  font-size: 32px;
  color: white;
  margin: 0px ;
}