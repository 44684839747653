@font-face {
    font-family: 'Abraham Lincoln';
    src: url('fonts/AbrahamLincoln.eot');
    src: url('fonts/AbrahamLincoln.eot?#iefix') format('embedded-opentype'),
        url('fonts/AbrahamLincoln.woff2') format('woff2'),
        url('fonts/AbrahamLincoln.woff') format('woff'),
        url('fonts/AbrahamLincoln.svg#AbrahamLincoln') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baskerville';
    src: url('fonts/Baskerville.eot');
    src: url('fonts/Baskerville.eot?#iefix') format('embedded-opentype'),
        url('fonts/Baskerville.woff2') format('woff2'),
        url('fonts/Baskerville.woff') format('woff'),
        url('fonts/Baskerville.svg#Baskerville') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baskerville';
    src: url('fonts/Baskerville-Italic.eot');
    src: url('fonts/Baskerville-Italic.eot?#iefix') format('embedded-opentype'),
        url('fonts/Baskerville-Italic.woff2') format('woff2'),
        url('fonts/Baskerville-Italic.woff') format('woff'),
        url('fonts/Baskerville-Italic.svg#Baskerville-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


:root {
    --textFont: 'Baskerville', Arial, sans-serif;
    --decorFont: 'Abraham Lincoln';
    --primaryColor: #9fbea0;
    --textOnPrimaryColor: #000000;
    --navbarBackgroundColor: #9fbea0;
    --navbarTextColor: #000000;
    --headerSectionBackgroundColor: #9fbea0;
    --headerSectionTextColor: #000000;
    --faqHeaderBackgroundColor: #000000;
    --faqHeaderTextColor: #000000;
    --wholePageMaxWidth: 900px
}

html {
    scroll-padding-top: var(--scrollPaddingTop);
}

body {
    font-family: var(--textFont);
    margin: auto;
    padding: 0;
    /*max-width: var(--wholePageMaxWidth);*/

}

h1,
h2,
h3 {
    /*font-family: 'Great Vibes', cursive;
    /* Additional styling for headings */
}

a {
    text-decoration: none;
    color: inherit;
}

header {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}

header img {
    width: 100%;
    height: auto;
    max-width: 500;
}

.upper-text {
    position: absolute;
    top: 0%;
    /* Adjust the top value to position the text as desired */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    /* Text color for the upper text */
}

.upper-text h2 {
    font-family: 'Parisienne', cursive;
    /* Apply the "Parisienne" font */
    font-size: 3rem;
    margin: 0;
}

.countdown-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
}

.countdown-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}



#countdown {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    z-index: 2;
    /* Ensure it's above the background */
}


#invitation {
    /*text-align: center;*/
    padding: 0px 0;
}

.invitation-content {
    font-size: 24px;
    color: #333;
    margin-left: 10px;
    margin-right: 10px;
    /* Text color for the invitation content */
}

.invitation-content h1 {
    font-size: 48px;
    /* Larger font size for the heading */
    margin-top: 10px;
    margin-bottom: 10px;
    /* Add some spacing below the heading */
}

.invitation-content a {
    text-decoration: none;
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

nav ul li {
    display: inline;
    margin: 0 20px;
}

nav ul li a {
    text-decoration: none;
    color: #fff;
}

.footer {
    height: 20px;
    /*background-color: var(--primaryColor);*/
    margin-top: 20px;
    display: flex;
    justify-content: center;
}