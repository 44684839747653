.rsvp-form,
.mail-form {
    max-width: 45rem;
    margin: 1.5rem auto 1.5rem;
}

.rsvp-form.hidden {
    display: none;
}

.form-group {
    margin: 0.75rem 0px;
}

.form-control {
    box-sizing: border-box;
    box-shadow: var(--primaryColor) 0px 2px 4px 0px;
    color: inherit;
    font-family: var(--text-font);
    width: 100%;
    background: var(--form-field-background-color);
    border: 1px solid var(--primaryColor);
    border-radius: 4px;
    outline: none;
    padding: 0.75rem;
}

.btn {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
    color: var(--textOnPrimaryColor);
    cursor: pointer;
    display: inline-block;
    font-family: var(--text-font);
    font-size: 1rem;
    font-weight: normal;
    line-height: 150%;
    text-align: center;
    vertical-align: middle;
    transition: color 0.2s ease-in-out 0s, background-color, transform, -webkit-transform;
    background: var(--primaryColor);
    border-width: 0px;
    border: 0px;
    border-style: initial;
    border-color: black;
    border-image: initial;
    border-radius: 4px;
    padding: 8px 12px;
}

.btn.btn-block {
    display: block;
    width: 100%;
}

.alert {
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 15px;
    text-align: center;
}

.alert.alert-success {
    background-color: #d6e9c6;
    border-color: #c9e2b4;
    color: #333;
}
.alert.alert-failure {
    background-color: #ffb7b5;
    border-color: #ffb7b5;
    color: #333;
}

.alert.hidden {
    display: none;
}

.form-group :disabled {
    color: rgba(0, 0, 0, 0.6);
    opacity: 75%;
}

.form-group.radio input {
    flex-shrink: 0;
    height: 1.5rem;
    text-align: center;
    vertical-align: middle;
    width: 1.5rem;
    /*
    border-radius:50%;
    appearance: none;
    box-shadow: 0 0 0 1px lightgrey;
    -webkit-box-shadow:0 0 0 1px lightgrey;
    -webkit-appearance: none;
    */

}

.form-group.radio input:checked {
    background-color: var(--primaryColor);
}

.form-group.radio label {
    padding: 10px;
    vertical-align: middle;
}

.form-group.checkbox input {
    flex-shrink: 0;
    height: 1.5rem;
    text-align: center;
    vertical-align: middle;
    width: 1.5rem;
    /*
    border-radius:50%;
    appearance: none;
    box-shadow: 0 0 0 1px lightgrey;
    -webkit-box-shadow:0 0 0 1px lightgrey;
    -webkit-appearance: none;
    */

}

.form-group.checkbox input:checked {
    background-color: var(--primaryColor);
}

.form-group.checkbox label {
    padding: 10px;
    vertical-align: middle;
}

.form-group.comment textarea {
    width: 100%;
    resize: none;
    font-family: var(--textFont);
}

.form-group.price {}

.form-group.price table {
    width: 100%;
}

.form-group.price.tablecell.amount {
    text-align: right;
}

.form-group.guestnames {}

.form-group.guestnames input[type=text] {
    width: 90%;
    margin-bottom: 5px;
}

.form-group.guestnames input[type=checkbox] {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 5px;
}

.form-group.subcheckbox {
    margin-left: 0.5rem;
}

.form-group.guestnames table {
    width: 100%;
}
.form-group.italydetail table {
    width: 100%;
    table-layout: fixed;
}
.form-group.italydetail td {
    word-wrap: break-word;
    overflow-wrap: break-word;
}