.section.organizers .organizers-list {
    list-style-type: none;
    padding: 0px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}


.section.organizers .organizer {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 900px;
    text-align: center;
    gap: 1rem 2rem;
    margin: 0px auto 2rem;
}

.section.organizers .organizer p {
    margin: 0px;
}

.section.organizers .location-map {
    flex-shrink: 0;
    position: relative;
    text-align: center;
    width: 200px;
}

.section.organizers .organizer-image.circle {
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

.section.organizers .location-map .organizer-image {
    height: 200px;
    position: relative;
    width: 200px;
    margin: 0px auto;
    overflow: hidden;
}

.section.organizers .organizer .name {
    font-size: 1.2rem;
    font-weight: bold;
}

.section.organizers .organizer .func {
    font-size: 0.9rem;
    font-style: italic;
}

.section.organizers .organizer .contact {
    font-size: 0.9rem;
    margin-top: 1rem;
}

.section.organizers .organizer .description {
    font-size: 1.1rem;
    margin-top: 1rem;
}

.section.organizers .organizer .contactIcons {
    display: flex;
    justify-content: space-evenly;
    max-width: 50%;
    margin: auto;
    margin-top: 0.7rem;
}

.section.organizers .organizer .contactIcons a {
    color: var(--primaryColor)
}