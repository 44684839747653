.faq-section .accordion {
    width: 300px;
    margin: 20px auto;
}

.faq-section .accordion-item {
    border: 1px dotted var(--primaryColor); 
    margin-bottom: 5px;
}

.faq-section .accordion-header {
   /* background-color: var(--faqHeaderBackgroundColor); */
    color: black; 
    padding: 10px;
    /*cursor: pointer;*/
    display: flex;
    align-items: center;
    /* Center vertically */
}

.faq-section .accordion-header .title {
    width: 100%;
    margin-left: 20px;
    /*
text-align: center;
align-items: center;
justify-content: center;
*/
}

.faq-section .accordion-content {
    padding: 10px;
}

.arrow {
    /*
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: #333 transparent transparent transparent;
    */
    transition: transform 0.3s;

}

.accordion-header .arrow {
    margin-left: 0px;
    /* Adjust the spacing between the arrow and text */
}

.open {
    transform: rotate(-180deg);
}

.faq-section {
    margin-top: 50px auto;
}

.faq-section .items {
    list-style-type: none;
    max-width: 900px;
    padding: 0px;
    margin: 0px auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    transition: 0.3s;

}